<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="排序" field="Ordinal" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="integer" v-model="data.Ordinal" placeholder="排序編號" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="不退稅" field="NotRefund" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-switch v-model="data.NotRefund" openLabel="是" closeLabel="否"></vxe-switch>
          </template>
        </vxe-form-item>
        <vxe-form-item title="出貨項目" field="OrderEstimatedShipmentListId" :item-render="{}" span="24">
          <template #default="{ data }">
            <select-box v-bind="selectorOptions" v-model="data.OrderEstimatedShipmentListId" :readonly="aid && table.isEditing" :disabled="aid && table.isEditing" />
          </template>
        </vxe-form-item>
        <!--<vxe-form-item title="出貨項目" field="OrderEstimatedShipmentListIds" :item-render="{}" span="24">
          <template #default="{ data }">
            <select-box v-bind="selectorOptions" v-model="data.OrderEstimatedShipmentListIds" :readonly="aid && table.isEditing" :disabled="aid && table.isEditing" />
          </template>
        </vxe-form-item> -->
        <vxe-form-item title="半成品品號" field="TaxRefundMapping2Id" :item-render="{}" span="24">
          <template #default="{ data }">
            <select-box v-bind="SemiSelectorOptions" :focusRefresh="true" v-model="data.TaxRefundMapping2Id" :readonly="aid && table.isEditing" :disabled="aid && table.isEditing" />
          </template>
        </vxe-form-item>
        <vxe-form-item v-if="aid" title="進貨單號" field="ProductNumberPurchaseRecordId" :item-render="{}" span="24">
          <template #default="{ data }">
            <select-box v-bind="ShipmentNumberSelectorOptions" :focusRefresh="true" v-model="data.ProductNumberPurchaseRecordId" :readonly="aid && table.isEditing" :disabled="aid && table.isEditing" />
          </template>
        </vxe-form-item>
        <vxe-form-item title="出貨數量" field="ShipmentCount" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="integer" v-model="data.ShipmentCount" placeholder="出貨數量" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="實際核發金額" field="ActualIssuedAmount" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="integer" v-model="data.ActualIssuedAmount" placeholder="實際核發金額" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <div class="flex justify-between">
      <div class="">
      </div>
      <div v-if="!aid" class="">
        <vxe-button class="m-3" @click="table.clear">重置表格</vxe-button>
      </div>
    </div>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Ordinal" title="排序" width="50" sortable >
      </vxe-column>
      <vxe-column field="ProductNumberPurchaseRecord.CustomsDeclarationDate" title="報關日期" :formatter="({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd')" width="100">
      </vxe-column>
      <vxe-column field="OrderEstimatedShipmentList.OrderNumber" title="訂單編號" width="130">
      </vxe-column>
      <vxe-column field="OrderEstimatedShipmentList.ProductNumber" title="品號" width="170">
      </vxe-column>
      <vxe-column field="TaxRefundMapping2.SemiFinishedProduct" title="半成品品號" width="170">
      </vxe-column>
      <vxe-column field="ProductNumberPurchaseRecord.PurchaseNumber" title="進貨單號" width="180">
      </vxe-column>
      <!-- <vxe-column field="AvailableQuantityMemo" title="當前可用量" :formatter="({ cellValue }) => toFixed(cellValue, 4)">
      </vxe-column> -->
      <vxe-column field="AvailableQuantityMemo" title="當前可用量">
      </vxe-column>
      <vxe-column field="AvailableQuantity" title="剩餘可用量">
      </vxe-column>
      <vxe-column field="ShipmentCount" width="100" title="出貨數量">
      </vxe-column>
      <vxe-column field="ProductNumberPurchaseRecord.CustomsDeclarationNumber" title="報單號碼">
      </vxe-column>
      <vxe-column field="UseQuantity" title="耗用量">
      </vxe-column>
      <vxe-column  v-if="aid" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }
    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        console.log('update(row: any)', row);
        console.log(row);
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      async delete(row: any) {
        // console.log(row.ProductNumberPurchaseRecordId, row.OrderEstimatedShipmentListId, row.TaxRefundMapping2Id)
        //await table.calculateAvailableQuantity(row, 'delete')
        try {          
          const data = await model?.dispatch("shipment/deleteItem", row.Id);
          if(data) {
            if(data?.constructor === Array) {
              table.data = data;
              await getDetail();
              table.reset();
            }
            CloudFun.send("info", {
              subject: "執行失敗",
              content: "刪除成功",
            });
            await grid.value.sort("ProductNumberPurchaseRecord.CustomsDeclarationDate", 'asc');
            emit('refresh');
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      },
      async calculateAvailableQuantity(row: any, type: string) {
        // 可用量須及時運算並顯示
        var data = grid.value.getTableData().fullData;
        // console.log(data);
        // 刪除 > 先排除掉預刪除的項目後，讓API將整個當前陣列重算
        // 修改 > 複製一個當前arry，並先進行取代(原data不動)
        var ary = type == 'delete'? 
             data.filter((e: any) => !(e.ProductNumberPurchaseRecordId == row.ProductNumberPurchaseRecordId && row.OrderEstimatedShipmentListId == e.OrderEstimatedShipmentListId && row.TaxRefundMapping2Id == e.TaxRefundMapping2Id))
             : JSON.parse(JSON.stringify(data));
        //console.log(ary);
        if(type == 'edit') {
          var exist = ary.find((i: any) => i.ProductNumberPurchaseRecordId == table.selectedRow.ProductNumberPurchaseRecordId 
                                            && table.selectedRow.OrderEstimatedShipmentListId == i.OrderEstimatedShipmentListId 
                                            && table.selectedRow.TaxRefundMapping2Id == i.TaxRefundMapping2Id);
          if(!exist) return CloudFun.send('error', { subject: '執行失敗', content: '請確認修改物件是否存在' });
          Object.assign(exist, row);
        }
        //ary = ary.reverse(); //順序反轉之後進DB才會是正確排序
        var params = {
          Item: null,
          List: ary.map((e: any) => { return {
            OrderEstimatedShipmentListId: e.OrderEstimatedShipmentListId, 
            TaxRefundMapping2Id: e.TaxRefundMapping2Id,
            ProductNumberPurchaseRecordId: e.ProductNumberPurchaseRecordId,
            ShipmentCount: e.ShipmentCount,
            Ordinal: e.Ordinal,
            AvailableQuantityMemo: 0, // 三個數值接預設為0，由API重新計算
            UseQuantity: 0,
            AvailableQuantity: 0,
          }}),
        }
        // console.log('row',row)
        if(params) {
          // console.log('params',params)
          try {
            const result = await model?.dispatch("productNumberPurchaseRecord/calculateAvailableQuantity", params);          
            if(result && result.length > 0) {
              //console.log(result)
              if(type == 'edit') {
                Object.assign(table.selectedRow, row); // 修改 > 確認有回傳值，進行原data的取代
              }
              // console.log(data);
              result.forEach((e: any) => {
                var exist = data.find((i: any) => i.ProductNumberPurchaseRecordId == e.ProductNumberPurchaseRecordId && i.OrderEstimatedShipmentListId == e.OrderEstimatedShipmentListId && i.TaxRefundMapping2Id == e.TaxRefundMapping2Id);
                //console.log(exist, e)
                if(exist) { // 回傳值進行取代 當前可用量、預估可用量
                  exist.AvailableQuantityMemo = e.AvailableQuantityMemo;
                  exist.AvailableQuantity = e.AvailableQuantity;
                  exist.UseQuantity = e.UseQuantity;
                  exist.Ordinal = e.Ordinal;
                  exist.NotRefund = e.NotRefund;
                  exist.RecordShipmentCount= e.RecordShipmentCount;
                  exist.ProductNo = e.ProductNo;
                  exist.SemiFinishedProduct = e.SemiFinishedProduct;
                }
              });
              await grid.value.reloadData(data); // 資料reload
              await grid.value.sort("Ordinal", 'asc');
            }

            if(type == 'delete') { // 刪除後須執行的動作
              grid.value.remove(row);
              table.reset();
            }            
          } catch (e: any) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: e,
            });
            return;
          }
        }
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.ShipmentCount = 0;
        table.formData.Ordinal = 0;
        table.formData.NotRefund = false;
        table.selectedRow = null;
        table.isEditing = false;
      },
      clear: () => {
        var result = confirm("確定要重置表單嗎?");
        if(result){
          table.reset();
          table.data = [];
        }
      },
      async save() {
        table.formData.OrderEstimatedShipmentList = null;
        if(!table.formData.OrderEstimatedShipmentListId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇出貨項目' });
        
        if (table.formData.OrderEstimatedShipmentListId) {
          const temp = await model?.dispatch("estimatedShipment/find", table.formData.OrderEstimatedShipmentListId)
          table.formData.OrderEstimatedShipmentList = { Id: temp.Id, ProductNumber: temp.ProductNumber, OrderNumber: temp.OrderNumber, TaxRefundQuantity: temp.TaxRefundQuantity };
          // console.log(table.formData.OrderEstimatedShipmentList)
        }
        console.log('table.formData.NotRefund', table.formData.NotRefund);
        if((!table.formData.OrderEstimatedShipmentListId || !table.formData.TaxRefundMapping2Id) && !table.formData.NotRefund) // || !table.formData.ProductNumberPurchaseRecordId
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇出貨資料' });

        if(table.formData.ShipmentCount <= 0 && !table.formData.NotRefund)
          return CloudFun.send('error', { subject: '執行失敗', content: '請輸入出貨數量' });

        if(table.formData.NotRefund){
          table.formData.TaxRefundMapping2Id = 274843;
        }
        if(table.isEditing) {
          // 編輯確認

          if(!table.formData.ProductNumberPurchaseRecordId && !table.formData.NotRefund)
            return CloudFun.send('error', { subject: '執行失敗', content: '請選擇出貨資料' });

          console.log('table checkover');
          // 如同內容修改=不需檢查，若有更換項目則檢查是否存在相同項目
          var current = grid.value.getTableData().fullData;
          var same = table.selectedRow.ProductNumberPurchaseRecordId == table.formData.ProductNumberPurchaseRecordId 
                        && table.selectedRow.OrderEstimatedShipmentListId == table.formData.OrderEstimatedShipmentListId 
                        && table.selectedRow.TaxRefundMapping2Id == table.formData.TaxRefundMapping2Id;
          if(!same && current.find((e:any) => e.ProductNumberPurchaseRecordId == table.formData.ProductNumberPurchaseRecordId && e.OrderEstimatedShipmentListId == table.formData.OrderEstimatedShipmentListId && e.TaxRefundMapping2Id == table.formData.TaxRefundMapping2Id))
            return CloudFun.send('error', { subject: '執行失敗', content: '出貨項目已存在' });
          
          // await table.calculateAvailableQuantity(table.formData, 'edit')
          try {
            // console.log(table.formData);
            console.log('shipment/updateItem');
            const data = await model?.dispatch("shipment/updateItem", { data: table.formData });
            if(data) {
              if(data?.constructor === Array) {
                table.data = data;
                await getDetail();
              }
              CloudFun.send("info", {
                subject: "執行失敗",
                content: "修改成功",
              });
              emit('refresh');
            }
          } catch (e: any) {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: e,
            });
          }
          // Object.assign(table.selectedRow, table.formData);
        } else {
          //新增自動分配
          console.log('新增自動分配');
          var current = grid.value.getTableData().fullData;
          
          if(props.aid) {
            if(!table.formData.ProductNumberPurchaseRecordId && !table.formData.NotRefund)
              return CloudFun.send('error', { subject: '執行失敗', content: '請選擇出貨資料' });
            if(current.find((e:any) => e.OrderEstimatedShipmentListId == table.formData.OrderEstimatedShipmentListId && e.TaxRefundMapping2Id == table.formData.TaxRefundMapping2Id && table.formData.ProductNumberPurchaseRecordId == e.ProductNumberPurchaseRecordId))
              return CloudFun.send('error', { subject: '執行失敗', content: '出貨項目已存在' });
            
            console.log('檢查出貨項目');
            try {
              table.formData.OrderEstimatedShipmentListRecordId = props.aid;
              const data = await model?.dispatch("shipment/insertItem", { data: table.formData });
              console.log('shipment/insertItem');
              if(data) {
                if(data?.constructor === Array) {
                  table.data = data;
                  await getDetail();
                }
                CloudFun.send("info", {
                  subject: "執行失敗",
                  content: "修改成功",
                });
                emit('refresh');
              }
            } catch (e: any) {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: e,
              });
            }

          } else {
            console.log('current.find((e:any)');
            if(current.find((e:any) => e.OrderEstimatedShipmentListId == table.formData.OrderEstimatedShipmentListId && e.TaxRefundMapping2Id == table.formData.TaxRefundMapping2Id))
              return CloudFun.send('error', { subject: '執行失敗', content: '出貨項目已存在' });

            const resultData = await getEstimatedAvailableQuantity(); //及時運算(新增)
            if(resultData?.constructor === Array) {
              table.data = resultData;
              await getDetail();
            }
          }
        }

        table.reset();
        await grid.value.sort("ProductNumberPurchaseRecord.CustomsDeclarationDate", 'asc');
        
      },
      rules: {
        ShipmentCount: [{ required: true }],
        OrderEstimatedShipmentListId: [{ required: true }],
      }
    })

    const getDetail = async() => {
      console.log('getDetail');
      for (const item of table.data) {
          const OrderEstimatedShipmentList = await model?.dispatch("estimatedShipment/find", item.OrderEstimatedShipmentListId)
          item.OrderEstimatedShipmentList = { Id: OrderEstimatedShipmentList.Id, ProductNumber: OrderEstimatedShipmentList.ProductNumber, OrderNumber: OrderEstimatedShipmentList.OrderNumber, TaxRefundQuantity: OrderEstimatedShipmentList.TaxRefundQuantity };
        if(!item.NotRefund){
          const TaxRefundMapping2 = await model?.dispatch("mapping2/find", item.TaxRefundMapping2Id)
          item.TaxRefundMapping2 = { Id: TaxRefundMapping2.Id, SemiFinishedProduct: TaxRefundMapping2.SemiFinishedProduct };          

          const ProductNumberPurchaseRecord = await model!.dispatch("productNumberPurchaseRecord/find", item.ProductNumberPurchaseRecordId)
          item.ProductNumberPurchaseRecord = { Id: ProductNumberPurchaseRecord.Id, PurchaseNumber: ProductNumberPurchaseRecord.PurchaseNumber, CustomsDeclarationDate: ProductNumberPurchaseRecord.CustomsDeclarationDate, CustomsDeclarationNumber: ProductNumberPurchaseRecord.CustomsDeclarationNumber };
        }
      }
    }
13
    const getNextOrdinal = () => {
      console.log('getNextOrdinal');
      const data = grid.value.getTableData().fullData;
      if(!data || data.length == 0) return 1; 
      var maxOrdinal = Math.max(...data.map((e: any) => e.Ordinal));
      return (maxOrdinal ?? 0) + 1;
    }

    const getEstimatedAvailableQuantity = async() => {
      console.log('getEstimatedAvailableQuantity');
      //進貨量 - 實際耗用量(出貨輸入量 * 用量(mp2)) = 可用量
      // if(系統輸入出貨量 > DB當前可用量) 拋出出貨量錯誤
      // 可用量 = 品號歷史進貨量 - (系統輸入出貨量 * 退稅用量)
      // DB當前可用量 -= (系統輸入出貨量 * 退稅用量)
      // 可用量須及時運算並顯示
      var data = grid.value.getTableData().fullData;
      // result為使用DTO，Item = 當前內容，List = 目前陣列內容(尚未新增資料)
      var result = {
        Item: {
          OrderEstimatedShipmentListId: table.formData.OrderEstimatedShipmentListId, 
          TaxRefundMapping2Id: table.formData.TaxRefundMapping2Id,
          ProductNumberPurchaseRecordId: table.formData.ProductNumberPurchaseRecordId,
          ShipmentCount: table.formData.ShipmentCount,
          AvailableQuantityMemo: table.formData.AvailableQuantityMemo,
          UseQuantity: 0,
          AvailableQuantity: 0,
          Ordinal: table.formData.Ordinal,
          NotRefund: table.formData.NotRefund,
        },
        List: data.map((e: any) => { return { 
          OrderEstimatedShipmentListId: e.OrderEstimatedShipmentListId, 
          TaxRefundMapping2Id: e.TaxRefundMapping2Id,
          ProductNumberPurchaseRecordId: e.ProductNumberPurchaseRecordId,
          ShipmentCount: e.ShipmentCount,
          AvailableQuantityMemo: e.AvailableQuantityMemo,
          DisplayAvailableQuantityMemo: e.DisplayAvailableQuantityMemo,
          DisplayRoundAvailableQuantityMemo: e.DisplayRoundAvailableQuantityMemo,
          DisplayCeilingAvailableQuantityMemo: e.DisplayCeilingAvailableQuantityMemo,
          UseQuantity: e.UseQuantity,
          DisplayUseQuantity: e.DisplayUseQuantity,
          DisplayRoundUseQuantity: e.DisplayRoundUseQuantity,
          DisplayCeilingUseQuantity: e.DisplayCeilingUseQuantity,
          AvailableQuantity: e.AvailableQuantity,
          DisplayAvailableQuantity: e.DisplayAvailableQuantity,
          DisplayRoundAvailableQuantity: e.DisplayRoundAvailableQuantity,
          DisplayCeilingAvailableQuantity: e.DisplayCeilingAvailableQuantity,
          Ordinal: e.Ordinal,
          NotRefund: e.NotRefund,
          DisplayShipmentCount: e.DisplayShipmentCount,
          DisplayRoundShipmentCount: e.DisplayRoundShipmentCount,
          DisplayCeilingShipmentCount: e.DisplayCeilingShipmentCount,
          RecordShipmentCount: e.RecordShipmentCount,
          ProductNo: e.ProductNo,
          SemiFinishedProduct: e.SemiFinishedProduct
        }}),
      }
      
      if(result) {
        // console.log('result',result)
        try {
          //及時運算，主要只計算新增進來的項目，並作當前可用量及預估可用量的替代，傳入當前陣列主要是運算相同項目(同進貨單號)的可用量累積
          const data = await model?.dispatch("productNumberPurchaseRecord/calculateAvailableQuantity", result);
          // console.log('data',data);
          return data;
          // console.log(data)
          // if(data) {
          //   table.formData.AvailableQuantityMemo = data.AvailableQuantityMemo;
          //   table.formData.AvailableQuantity = data.AvailableQuantity;
          //   table.formData.UseQuantity = data.UseQuantity;
          //   return table.formData.AvailableQuantity;
          // }
          // if(list && list.length > 0) {
          //   list.forEach((e: any) => {
          //     var exist = data.find((i: any) => i.ProductNumberPurchaseRecordId == e.ProductNumberPurchaseRecordId && i.OrderEstimatedShipmentListId == e.OrderEstimatedShipmentListId && i.TaxRefundMapping2Id == e.TaxRefundMapping2Id);
          //     console.log(exist, e)
          //     if(exist) {
          //       exist.AvailableQuantityMemo = e.AvailableQuantityMemo;
          //       exist.AvailableQuantity = e.AvailableQuantity;
          //     }
          //   });
          // }
          // await grid.value.reloadData(data);
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
          return null;
        }
      }

      return null;
    }

    watch(() => props.aid, async(value) => {
      if(value) {
        // console.log(props.data)        
        if(props.data && props.data.length > 0) {
          table.data = props.data;
          await getDetail();
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      //multiselect: true,
      textField: "ProductNumber",
      valueField: "Id",
      columns: [
        {
          field: "OrderNumber",
          title: "訂單編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductNumber",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "TaxRefundDocumentNumber",
          title: "文號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      formatText: (row) => row.ProductNumber && row.OrderNumber ? `${row.OrderNumber}-${row.ProductNumber}` : row.ProductNumber ?? row.OrderNumber,
      showHeader: true,
      promises: {
        find: async value => {
          return await model!.dispatch("estimatedShipment/find", value) 
        },
        query: async params => {
          return await model!.dispatch("estimatedShipment/query", params)
        }
      }
    };

    const SemiSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "SemiFinishedProduct",
      valueField: "Id",
      columns: [
        {
          field: "SemiFinishedProduct",
          title: "半成品品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "TaxRefundName",
          title: "半成品品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "TaxRefundSpecifications",
          title: "半成品規格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
        {
          field: "DocumentNo",
          title: "文號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Quantity",
          title: "用量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      // formatText: (row) => row.ProductNumber && row.OrderNumber ? `${row.OrderNumber}-${row.ProductNumber}` : row.ProductNumber ?? row.OrderNumber,
      showHeader: true,
      promises: {
        find: async value => await model!.dispatch("mapping2/find", value),
        query: async params => {
          var name = table.formData?.OrderEstimatedShipmentList?.ProductNumber;
          // console.log(name);
          if(name) params.condition = new Condition("ProductNo", Operator.Equal, name).and(params.condition!);
          // params.sortings!.push({ column: 'CreatedTime', order: 1 });
          // var ids = table.formData?.OrderEstimatedShipmentListIds;
          // if(ids) params.condition = new Condition("OrderEstimatedShipmentListIds", Operator.In, ids).and(params.condition!);

          var result = await model!.dispatch("mapping2/query", params);
          // console.log(result);
          return result;
        }
      }
    };

    const ShipmentNumberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "PurchaseNumber",
      valueField: "Id",
      columns: [
        {
          field: "PurchaseNumber",
          title: "進貨單號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "PurchaseQuantity",
          title: "進貨量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "AvailableQuantity",
          title: "可用量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
        {
          field: "CustomsDeclarationNumber",
          title: "報單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      // formatText: (row) => row.ProductNumber && row.OrderNumber ? `${row.OrderNumber}-${row.ProductNumber}` : row.ProductNumber ?? row.OrderNumber,
      showHeader: true,
      promises: {
        find: async value => await model!.dispatch("productNumberPurchaseRecord/find", value),
        query: async params => {
          var name = table.formData?.TaxRefundMapping2?.SemiFinishedProduct;
          // console.log(name);
          if(name) params.condition = new Condition("ProductNo", Operator.Equal, name).and(params.condition!);
          // params.sortings!.push({ column: 'CreatedTime', order: 1 });
          var result = await model!.dispatch("productNumberPurchaseRecord/query", params);
          // console.log(result);
          return result;
        }
      }
    };

    watch(() => table.formData.OrderEstimatedShipmentListId, async(value) => {
      table.formData.OrderEstimatedShipmentList = null;      
      if(value) {
        const temp = await model?.dispatch("estimatedShipment/find", table.formData.OrderEstimatedShipmentListId)
        table.formData.OrderEstimatedShipmentList = { Id: temp.Id, ProductNumber: temp.ProductNumber, OrderNumber: temp.OrderNumber, TaxRefundQuantity: temp.TaxRefundQuantity };
        // console.log(table.formData.OrderEstimatedShipmentList)
      } else {
        table.formData.TaxRefundMapping2Id = null;
        // table.formData.ProductNumberPurchaseRecordId = null;
      }
    }, { immediate: true });

    // watch(() => table.formData.TaxRefundMapping2Id, async(value) => {
    //   table.formData.TaxRefundMapping2 = null;
    //   if(value) {
    //     const temp = await model?.dispatch("mapping2/find", table.formData.TaxRefundMapping2Id)
    //     table.formData.TaxRefundMapping2 = { Id: temp.Id, SemiFinishedProduct: temp.SemiFinishedProduct };
    //     // console.log(table.formData.OrderEstimatedShipmentList)
    //   } else {
    //     table.formData.ProductNumberPurchaseRecordId = null;
    //   }
    // }, { immediate: true });

    // watch(() => table.formData.ProductNumberPurchaseRecordId, async(value) => {
    //   table.formData.ProductNumberPurchaseRecord = null;
    //   if(value) {
    //     const temp = await model?.dispatch("productNumberPurchaseRecord/find", table.formData.ProductNumberPurchaseRecordId)
    //     table.formData.ProductNumberPurchaseRecord = { Id: temp.Id, PurchaseNumber: temp.PurchaseNumber };
    //     table.formData.AvailableQuantityMemo = temp.AvailableQuantity ?? 0;
    //     // console.log(table.formData.OrderEstimatedShipmentList)
    //   }
    // }, { immediate: true });

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      SemiSelectorOptions,
      ShipmentNumberSelectorOptions,
      model,
      formatDate,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
